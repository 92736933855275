<script>
export default {
  data() {
    return {
      data: {
      }
    };
  },
  components: {
  },
  created: function () {
  },
  methods: {
  }
};
</script>

<template>
  <div class="mt-2">
    <ul class="pagination pagination-separated justify-content-center mb-0">
      <li class="page-item disabled"><a class="page-link" href="javascript:void(0);" target="_self">
        <i class="mdi mdi-chevron-left"></i></a>
      </li>
      <li class="page-item active"><a class="page-link" href="javascript:void(0);" target="_self">1</a></li>
      <li class="page-item"><a class="page-link" href="javascript:void(0);" target="_self">2</a></li>
      <li class="page-item"><a class="page-link" href="javascript:void(0);" target="_self">3</a></li>
      <li class="page-item"><a class="page-link" href="javascript:void(0);" target="_self">4</a></li>
      <li class="page-item"><a class="page-link" href="javascript:void(0);" target="_self">5</a></li>
      <li class="page-item"><a class="page-link" href="javascript:void(0);" target="_self">
        <i class="mdi mdi-chevron-right"></i></a>
      </li>
    </ul>
  </div>
</template>