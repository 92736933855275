<script>
export default {
  data() {
    return {
    };
  },
  props: {
    type: {
      type: String,
      default: function() {
        return '';
      }
    },
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
  },
  components: {
  },
  created: function () {
  },
  methods: {
    getType() {
      switch(this.type) {
        case 'podcasts':
          return 'podcast';
        case 'profiles':
          return 'profile';
        case 'posts':
            return 'post';
        default:
        case 'episodes':
          return 'episode';
      }
    },
  },
  computed: {
    getUrl() {
      var link;
      if(this.data.link)
        link = this.data.link;
      else
        link = this.data.id;

      var type = this.getType();
      return 'https://busycast.com/' + type + '/' + link;
    }
  }
};
</script>

<template>
  <div class="border border-dashed"></div>

  <div class="pb-3 mt-3">
    <h5 class="fs-14 mb-1">
      <a :href="getUrl" target="_self">{{ data.title }}</a>
    </h5>
    <p class="mb-0"><a class="text-primary" :href="getUrl">{{ getUrl }}</a></p>
    <p class="text-muted mb-0">{{ data.memo }}</p>
    <!-- <ul class="list-inline d-flex align-items-center g-3 text-muted fs-14 mb-0">
      <li class="list-inline-item me-3"><i class="ri-thumb-up-line align-middle me-1"></i>10 </li>
      <li class="list-inline-item me-3"><i class="ri-question-answer-line align-middle me-1"></i>8 </li>
      <li class="list-inline-item">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0"><i class="ri-user-line"></i></div>
          <div class="flex-grow-1 fs-13 ms-1"><span class="fw-medium">Themesbrand</span></div>
        </div>
      </li>
    </ul> -->
  </div>
</template>